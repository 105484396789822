import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
    constructor() {
        this.extraSymbolsRelation = {
            9: 1,
            10: 2,
            11: 3
        }
    }
    parseInit(data) {
        let newData = {};

        newData.action = 'init';
        newData.balance = data.balance;
        newData.params = {

            betPossible: data.bets_available
        };
        newData.current = {
            bet: data.bet_amount,
        };
        newData.currencyInfo = {
            decimals: data.decimals,
            symbol: data.currency,
            currency: data.currency
        };
        newData.paytableInfo = data.slot_machine.paytable;

        newData.freebets = data.freebets

        return newData;
    }

    parseSpin(data) {
        let newData = {};

        newData.action = 'spin';
        newData.balance = data.balance;
        newData.spin = {
            reels: data.spin?.reels,
            winnings: this._makeWinnings(data),
            extraReels: data.chili.map(symbolIndex => [this.extraSymbolsRelation[symbolIndex]]),
            extraCoef: data.chiliCoef,
            bet: data.bet_amount
        };

        newData.freebets = data.freebets

        return newData;
    }

    _makeWinnings(data) {
        const spinData = data.spin;
        let winnings = {};
        winnings.totalWin = 0;

            winnings.lines = [];
        if (spinData) {
            for (const lineNumber in spinData.win_lines) {
                const lineLeng = spinData.lines_icons[lineNumber];
                if(lineLeng < 3) continue;
                let line = {};
                line.path = EntryPoint.configData.payLines[lineNumber - 1].concat().splice(0, lineLeng);
                line.line = lineNumber - 1;
                line.count = lineLeng;
                line.win = spinData.win_lines[lineNumber] * data.bet_amount / 20;

                winnings.lines.push(line);

                winnings.totalWin = spinData.total_win;
            }
        }

        if (data.isScatters) {
            winnings.scatters = [];
            let scatter = {};
            scatter.symbol = 1;
            const pathArr = new Array(5);
            data.scatters.forEach(reelData => {
                pathArr[reelData[0]] = reelData[1]+1;
            })
            scatter.path = pathArr;
            scatter.count = data.scatters.length;
            scatter.win = data.scatters_win;

            winnings.scatters.push(scatter);

            winnings.totalWin += scatter.win;
        }
        winnings.totalWin *= data.chiliCoef;



        return winnings;
    }

}
