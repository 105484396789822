import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class WinAmountAnimated {
  constructor(element, style = {}) {
    this.label = element
    this.onStop = () => {};
    this.onComplete = () => {};
  }

  animate(startValue = 0, endValue, decimals = 2, duration) {
    this.needStop = false;
    this.label.text = MoneyFormat.formatCurrency(startValue, this.decimals);

    this.label.visible = true;
    this.startValue = startValue;
    this.endValue = endValue;
    this.decimals = decimals;
    const arrForDecimals = MoneyFormat.formatCurrency(endValue, this.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;
    this.duration = duration;
    this.startTime = Date.now();
    requestAnimationFrame(this.animateValueChanged.bind(this));
  }

  set(value) {
    this.label.text = this._formatValue(value);
  }

  stop(){
    this.needStop = true;
  }

  _onStop() {
    this.onStop();
  };

  animateValueChanged() {
    if (this.needStop) return;
    const time = Date.now() - this.startTime;

    if (time >= this.duration) {
      this.label.text = this._formatValue(this.endValue);
      return this._onStop();
    }

    const progress = time / this.duration;
    const amount = (this.endValue - this.startValue) * progress + this.startValue;
    this.label.text = amount.toFixed(this.decimals);

    requestAnimationFrame(this.animateValueChanged.bind(this));
  };

  _formatValue(value) {
    return MoneyFormat.formatCurrency(value, this.decimals, false);
  }
}
