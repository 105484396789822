const uiColors = {
  overlay: {
    default: 'rgba(52, 3, 3, 0.9)',
  },
  primary: {
    default: '#DF1E23',
    hover: '#FB4449',
    pressed: '#C91217',
    disabled: '#8C282B',
  },
  secondary: {
    default: '#FF7100',
    hover: '#FF8A2E',
    pressed: '#E76100',
  },
  toggled: {
    default: '#F00073',
  },
  cancel: {
    default: '#930D11',
    hover: '#CA090F',
    pressed: '#8A0307',
  }
};


const uiConfig = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
      bottom_panel: { top: 0, left: 0 },
    },
  },
  V: {
    colors: uiColors,
    offsets: {
      top: -30,
      left: 0,
    },
  }
}

export {
  uiConfig,
}
