import Flashlib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from '../../model/GameModel';
import eEventTypes from '../../enums/eEventTypes';

export default class ExtraReelsContainer extends Flashlib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();

    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRect(0, 15, 340, 68);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }

  init() {
    this.ControllerReels = this.getChildByName('controllerReels');
    this.ControllerReels.init(EntryPoint.configData.reels.availableReelsExtra);
    this.ControllerReels.startSpeeds = 0.4;
    this.ControllerReels.symbolsCountForStart = 2
    this.ControllerReels.isBounce = false;
  }

  addListeners() {
    this.ControllerReels.onAllReelsStopped = () => GlobalDispatcher.dispatch(eEventTypes.EET_EXTRA_REELS_STOPPED);
    GlobalDispatcher.add(eEventTypes.EET_FOURTH_REEL_STOPPED, this.stop.bind(this));
  }

  start() {
    this.ControllerReels.start();
  }


  stop() {
    this.data = GameModel.extraReels;
    if (EntryPoint.GameSettings.quickStop) {
      this.ControllerReels.symbolsCountForStop = 1;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 0.8, 0.5, 0.1];
    } else {
      this.ControllerReels.symbolsCountForStop = [1, 2, 3];
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 0.9, 0.8, 0.05];
    }
    this.ControllerReels.stop(this.data);
  }
}
